@mixin services() {
	section#services {
		background-color: $primary;
		color: #fff;

		h4 {
			font-weight: bold;
		}

		ul {
			padding: 0;

			li {
				list-style-type: none;
				margin: 1rem 0;
			}
		}

		.btn {
			font-size: 1.5rem;
			padding: 0.25rem 1rem;
		}

		.image-container {
			box-shadow: 0px 0px 0px 24px rgba(255, 255, 255, 1);
			margin-bottom: 48px;
		}
	}
}

@mixin services-768() {
	section#services {
		position: relative;
		text-align: center;

		h1 {
			font-size: 3rem;
		}

		.services-content {
			margin-top: 20rem;
			margin-bottom: 5rem;
			text-align: left;
		}

		.workers-img {
			position: absolute;
			right: 10rem;
			top: 5rem;
		}
	}
}

@mixin services-992() {
	section#services {

		h1 {
			font-size: 2.5rem;
		}

		.workers-img {
			right: 20rem;
		}

		.btn {
			margin-top: -9rem;
		}
	}
}

@mixin services-1200() {
	section#services {
		h1 {
			font-size: 3.5rem;
		}
	}
}