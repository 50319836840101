// local google fonts

/** ToDo: * mach aus playfair display und roboto jeweils ein mixin, so wie die anderen unten */


@mixin playfair-display() {
  /* playfair-display-regular - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/playfair-display-v22-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-500 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/playfair-display-v22-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-500.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-600 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/playfair-display-v22-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-600.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-700 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/playfair-display-v22-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-700.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-800 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/playfair-display-v22-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-800.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-800.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-900 - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/playfair-display-v22-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-900.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-900.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/playfair-display-v22-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-500italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/playfair-display-v22-latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-500italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-500italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-600italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/playfair-display-v22-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-600italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-700italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/playfair-display-v22-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-700italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-800italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/playfair-display-v22-latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-800italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-800italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
  /* playfair-display-900italic - latin */
  @font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/playfair-display-v22-latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/playfair-display-v22-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/playfair-display-v22-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/playfair-display-v22-latin-900italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/playfair-display-v22-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/playfair-display-v22-latin-900italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
  }
}


@mixin roboto-condensed() {
  /* roboto-condensed-300 - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-condensed-v19-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto-condensed-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-condensed-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-condensed-v19-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-condensed-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-condensed-v19-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
  /* roboto-condensed-regular - latin */
  @font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-condensed-v19-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/roboto-condensed-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-condensed-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/roboto-condensed-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/roboto-condensed-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/roboto-condensed-v19-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  }
}

@mixin webfont-yanone-kaffeesatz {
	/* yanone-kaffeesatz-regular - latin */
	@font-face {
		font-family: 'Yanone Kaffeesatz';
		font-style: normal;
		font-weight: 400;
		src: url('../fonts/yanone-kaffeesatz-v18-latin-regular.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/yanone-kaffeesatz-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/yanone-kaffeesatz-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/yanone-kaffeesatz-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('../fonts/yanone-kaffeesatz-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/yanone-kaffeesatz-v18-latin-regular.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
	}
	/* yanone-kaffeesatz-300 - latin */
	@font-face {
		font-family: 'Yanone Kaffeesatz';
		font-style: normal;
		font-weight: 300;
		src: url('../fonts/yanone-kaffeesatz-v18-latin-300.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/yanone-kaffeesatz-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/yanone-kaffeesatz-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/yanone-kaffeesatz-v18-latin-300.woff') format('woff'), /* Modern Browsers */
		url('../fonts/yanone-kaffeesatz-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/yanone-kaffeesatz-v18-latin-300.svg#YanoneKaffeesatz') format('svg'); /* Legacy iOS */
	}
}

@mixin webfont-montserrat {
	/* montserrat-100 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 100;
		src: url('../fonts/montserrat-v15-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-100.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-100italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 100;
		src: url('../fonts/montserrat-v15-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-100italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-200italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 200;
		src: url('../fonts/montserrat-v15-latin-ext_latin-200italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-200italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-200italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-200 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 200;
		src: url('../fonts/montserrat-v15-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-300 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 300;
		src: url('../fonts/montserrat-v15-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-300italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 300;
		src: url('../fonts/montserrat-v15-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-300italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-regular - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		src: url('../fonts/montserrat-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 400;
		src: url('../fonts/montserrat-v15-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-500 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		src: url('../fonts/montserrat-v15-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-500italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 500;
		src: url('../fonts/montserrat-v15-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-500italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-600 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		src: url('../fonts/montserrat-v15-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-600italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 600;
		src: url('../fonts/montserrat-v15-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-600italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-700 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		src: url('../fonts/montserrat-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-700italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 700;
		src: url('../fonts/montserrat-v15-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-700italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-800 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 800;
		src: url('../fonts/montserrat-v15-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-800italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 800;
		src: url('../fonts/montserrat-v15-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-800italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-900 - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 900;
		src: url('../fonts/montserrat-v15-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
	/* montserrat-900italic - latin-ext_latin */
	@font-face {
		font-family: 'Montserrat';
		font-style: italic;
		font-weight: 900;
		src: url('../fonts/montserrat-v15-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
		src: local(''),
		url('../fonts/montserrat-v15-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/montserrat-v15-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
		url('../fonts/montserrat-v15-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/montserrat-v15-latin-ext_latin-900italic.svg#Montserrat') format('svg'); /* Legacy iOS */
	}
}

@mixin nunito-sans {
	/* nunito-sans-200 - latin */
@font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 200;
	src: url('../fonts/nunito-sans-v12-latin-200.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/nunito-sans-v12-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/nunito-sans-v12-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-200.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/nunito-sans-v12-latin-200.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-300 - latin */
  @font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/nunito-sans-v12-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/nunito-sans-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/nunito-sans-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/nunito-sans-v12-latin-300.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-regular - latin */
  @font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/nunito-sans-v12-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/nunito-sans-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/nunito-sans-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/nunito-sans-v12-latin-regular.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-600 - latin */
  @font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/nunito-sans-v12-latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/nunito-sans-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/nunito-sans-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-600.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/nunito-sans-v12-latin-600.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }
  /* nunito-sans-800 - latin */
  @font-face {
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/nunito-sans-v12-latin-800.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/nunito-sans-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/nunito-sans-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-800.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/nunito-sans-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/nunito-sans-v12-latin-800.svg#NunitoSans') format('svg'); /* Legacy iOS */
  }
}