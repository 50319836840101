@mixin navbar($navbar-brand-is-absolute: false) {
	.navbar, .navbar-toggler, .navbar-brand {
		padding: 0;
		line-height: $navbar-height-large;
		border: none;
	}

	.navbar {
		background-color: $tertiary;
		box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
		text-align: center;
		line-height: $navbar-height;
		vertical-align: middle;

		font-size: 1.25rem; // 25% more than in _headline.scss

		@include transition(all 0.3s linear);

		// > .background {
		// 	background-color: #fff;
		// }

		.navbar-brand {
			background-color: #fff;

			@if $navbar-brand-is-absolute {
				position: absolute;
				top: 0;
				z-index: 1;
			}

			-webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.6);
			box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.6);

			img {
				height: 8rem;
				margin: 0;
			}
		}

		.navbar-nav-container {
			display: none;

			.search {
				background-color: transparent;
				border: none;
				padding-left: 1rem;
				background-image: url('../images/inp-search.png');
				background-repeat: no-repeat;
				line-height: 1;
			}

			i {
				background-color: #fff;
				border-radius: 100%;
				padding: 0.5rem;
				margin-right: 1rem;
				color: #000;

				&:hover, &:focus, &:active {
					color: $primary;
					@include transition(all 0.3s ease-in-out);
				}
			}
		}
	}

	.navbar-nav .nav-item {
		position: relative;
		line-height: $navbar-height-large;

		.nav-link {
			font-family: $headings-font-family;
			color: #fff;
			padding: 0 2rem;
			// font-size: $font-size-base;
			position: relative;
			font-weight: 600;
		}

		&.active, &:hover, &:focus, &:active {
			.nav-link {
				color: $secondary;
			}
		}
	}

	.btn {
		white-space: nowrap;
	}
}

@mixin navbar-576($navbar-brand-is-absolute: false) {
	.navbar .navbar-brand img {
		height: 9rem;
	}
}

@mixin navbar-992($navbar-brand-is-absolute: false) {
	.navbar {
		line-height: $navbar-height-large;

		@if $navbar-brand-is-absolute {
			.navbar-brand {
				top: -2.7rem;
			}
		}

		.menu-apply {
			a {
				text-decoration: none;
				color: #fff;
				font-weight: 600;

				&:first-child {
					text-transform: uppercase;
				}

				&:nth-child(1n+1) {
					color: $secondary;

					&:hover, &:focus, &:active, &.active {
						color: white;
					}

					&:after {
						content: '|';
						margin: 0 0.5rem;
					}
				}

				&:nth-child(2n) {
					&:after {
						color: #fff;
					}
				}

				&:last-child {
					color: #fff;
					&:after {
						content: '';
					}
				}
			}
		}
	}

	.navbar-nav .nav-item {
		text-align: right;

		.nav-link {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

@mixin navbar-elements-992() {
	.navbar .navbar-elements {
		overflow: visible;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		display: flex;
		text-align: center;
		margin-right: 0;

		.nav-item {
			line-height: 2.5rem;
		}

		.last-item {
			background-color: $secondary;
			color: #fff;

			span {
				display: block;
			}

			i {
				font-size: 1.5rem;
				padding-top: 1rem;
			}

			.nav-link {
				color: inherit;
				padding: .5rem 1rem;
				position: relative;
				top: 5px;
			}
		}

		&:before {
			content: " ";
			position: absolute;
			margin-left: -120px;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0 120px 96px 0;
			border-color: transparent $secondary transparent transparent;
		}
	}
}