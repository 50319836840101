@mixin has-fixed-top($navbar-is-translucent: false) {
	body.admin-bar.has-fixed-top .fixed-top {
		margin-top: $wpadminbar-small-height;

		@media screen and (max-width: 782px) {
			margin-top: $wpadminbar-height;
		}
	}

	@if not $navbar-is-translucent {
		body.has-fixed-top {
			padding-top: $navbar-height + $navbar-padding-y;
		}
	}
}

@mixin has-fixed-top-576($navbar-is-translucent: false) {
	@if not $navbar-is-translucent {
		body.has-fixed-top {
			padding-top: $headline-height + $navbar-height-large + $navbar-padding-y*2;
		}
	} @else {
		body.has-fixed-top {
			padding-top: 0;
		}
	}
}
