@mixin flip-cards() {

	.vertical-container {
		padding-top: .9375rem*2;
		padding-bottom: .9375rem*2;
	}

	.vertical-container > div {
		margin-bottom: .9375rem*2;
	}

	.flip .card {
		.front > div {
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			height: 100%;
		}

		.front, .back {
			> div {
				height: 100%;
			}
		}
	}

	.disabled .card {
		opacity: 0.5;
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		cursor: not-allowed;
	}

	.flip {
		-webkit-perspective: 800;
		-ms-perspective: 800;
		-moz-perspective: 800;
		-o-perspective: 800;
		position: relative;

		.card {
			-webkit-transform-style: preserve-3d;
			-webkit-transition: 0.5s;
			-moz-transform-style: preserve-3d;
			-moz-transition: 0.5s;
			-ms-transform-style: preserve-3d;
			-ms-transition: 0.5s;
			-o-transform-style: preserve-3d;
			-o-transition: 0.5s;
			transform-style: preserve-3d;
			transition: 0.5s;
			background-color: transparent;
		}

		.card.flipped {
			transform:rotatey(-180deg);
			-ms-transform:rotatey(-180deg); /* IE 9 */
			-moz-transform:rotatey(-180deg); /* Firefox */
			-webkit-transform:rotatey(-180deg); /* Safari and Chrome */
			-o-transform:rotatey(-180deg); /* Opera */
		}

		.front, .back {
			position: absolute;
			z-index: 2;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			backface-visibility: hidden;  /* W3C */
			-webkit-backface-visibility: hidden; /* Safari & Chrome */
			-moz-backface-visibility: hidden; /* Firefox */
			-ms-backface-visibility: hidden; /* Internet Explorer */
			-o-backface-visibility: hidden; /* Opera */
			//background-color: $card-bg;
			//border: $card-border-width solid $card-border-color;
		}

		.front {
			z-index: 1;
			transform: rotateX(0deg); // fix firefox backface-visibility bug (https://bugzilla.mozilla.org/show_bug.cgi?id=1201471), https://stackoverflow.com/questions/9604982/backface-visibility-not-working-properly-in-firefox-works-in-safari
		}

		.back {
			transform:rotatey(-180deg);
			-ms-transform:rotatey(-180deg); /* IE 9 */
			-moz-transform:rotatey(-180deg); /* Firefox */
			-webkit-transform:rotatey(-180deg); /* Safari and Chrome */
			-o-transform:rotatey(-180deg); /* Opera */
			padding-top: 0;
			padding-bottom: 0;
		}
	}

}