@mixin page() {
	h1 {
		color: $primary;
		font-weight: 800;
		text-transform: uppercase;
		border-top: 1px solid $primary;
		border-bottom: 1px solid $primary;
		display: inline-block;
		line-height: 1.5;

		span {
			vertical-align: middle;
		}
	}

	h2 {
		color: $primary;
	}

	h3 {
		color: $tertiary;
	}

	.ul-tick {
		text-align: left;
		margin: 0;
		padding: 0;
		// display: inline-block; // why?
		display: block;

		li {
			display: flex;

			&:before {
				content: '\f058';
				font-family: 'Font Awesome 5 Free';
				margin-right: 0.5rem;
				color: $primary;
			}
		}
	}
}