// do not place anything else in this file

@mixin wp-adminbar() {
	@media screen and (max-width: 600px) {
		html #wpadminbar {
			position: fixed;
		}
	}
}

@mixin wp-widgets() {
	.widget {
		margin-bottom: 1rem;
	}

	.widget-title {
		text-transform: uppercase;
		font-size: 1.5rem;
	}
}

@mixin skip-link() {
	a.skip-link {
		height: 1px;
		width: 1px;
		position: absolute;
		overflow: hidden;
		top: -10px;
	}
}