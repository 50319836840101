@mixin faq {
	section#faq {
		background-color: $tertiary;
		padding: 5rem 0;

		.accordion-item {
			background-color: #50504f;
			color: #fff;
			margin-bottom: 1rem;
			border: none;

			.accordion-button {
				color: $tertiary;
				background-color: #a0d2eb;
				font-weight: 600;
				box-shadow: none;
			}
		}
	}
}