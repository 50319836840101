@mixin jumbotron() {
	.home #jumbotron,
	.page-template-employee #jumbotron {
		background-image:
			linear-gradient(
				0deg,
				rgba(183,224,241,0.8) 0,
				rgba(183,224,241,0.8) 0,
				rgba(183,224,241,0.8) 100%,
				rgba(183,224,241,0.8) 100%
			),
			url('/wp-content/uploads/2022/03/background.jpg');
	}

	.page-template-default #jumbotron,
	.page-template-employer #jumbotron,
	.page-template-about #jumbotron {
		background-image:
			linear-gradient(
				0deg,
				rgba(183,224,241,0.4) 0,
				rgba(183,224,241,0.4) 0,
				rgba(183,224,241,0.4) 100%,
				rgba(183,224,241,0.4) 100%
			),
			url('/wp-content/uploads/2022/06/stadt-offenburg-header-mr-jobfinder.jpg');
	}

	section#jumbotron {
		background-size: cover;
		background-position: center center;
		padding-top: $navbar-brand-overlap*2;

		color: #fff;
		min-height: 50vh;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		position: relative;

		// padding-top: $navbar-brand-overlap*4;

		h1 {
			color: #fff;
			font-size: 2.25rem;
			font-weight: 100;
		}

		form {
			z-index: 1;
		}

		.search-container-input-fields {
			display: inline-block;

			border-top: 1px solid $primary;
			border-bottom: 1px solid $primary;

			.select2-container--default {
				@extend .col;
			}

			.select2-container--default .select2-selection--single .select2-selection__arrow {
				display: none;
			}

			.select2-container--default .select2-selection--single {
				background: transparent;
				border: none;
				border-radius: 0;
			}

			input, .select2-selection > * {
				font-weight: 800;
				font-size: 1.25rem;
				text-align: center;
				color: $primary;
				display: block;
				height: 100%;

				&::placeholder, .select2-selection__placeholder {
					color: #a1d2eb;
				}
			}
		}

		.select2-selection__rendered {
			@extend .form-control;
		}

		.expand-search {
			display: block;
			margin-top: 1rem;
			text-decoration: none;
			color: $tertiary;
			font-weight: 600;
			font-size: 0.9rem;
		}
	}
}

@mixin jumbotron-576() {
	section#jumbotron {
		h1 {
			font-size: 3.5rem;
		}

		.input-group {
			input {
				font-size: 1.75rem;
				height: 3.4rem;
			}
		}
	}
}

@mixin jumbotron-768() {
	section#jumbotron {
		min-height: 60vh;
	}
}

@mixin jumbotron-992() {
	section#jumbotron {
		padding-top: $navbar-brand-overlap-large*2;
		min-height: 70vh;

		.input-group {
			input {
				font-size: 1.75rem;
			}
		}

		.search {
			// max-width: 600px;
			margin-bottom: 6rem!important;
		}

		h1 {
			font-size: 5.5rem;
		}
	}
}

@mixin jumbotron-1200() {
	section#jumbotron {
		min-height: 80vh;

		.search {
			// max-width: 700px;
		}

		h1 {
			font-size: 5rem;
		}
	}
}

@mixin jumbotron-1400() {
}

@mixin jumbotron-1500() {
}

@mixin jumbotron-1700() {
}

@mixin owl-carousel-in-jumbotron() {
	section#jumbotron {
		.owl-carousel {
			z-index: 0;

			display: flex;
			margin-top: auto;

			.owl-stage {
				display: flex;
				// align-items: end; // keep the owl-items at the end, because of cut off feet
			}

			.owl-item {
				display: flex;
				margin-top: auto;

				.owl-carousel-inner-item {
					padding-left: var(--bs-gutter-x,.75rem);
					padding-right: var(--bs-gutter-x,.75rem);

					margin-left: auto;
					display: flex;
					align-items: center;

					img {
						// height: fit-content;
						// height: min-content;
						// width: auto; // rely on max-width
						// width: 50%;

						visibility: hidden;
						// max-width: 50%; => because no split
					}
				}
			}
		}
	}
}

@mixin form-in-jumbotron() {
	section#jumbotron {
		#form-in-jumbotron-container {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}
}

@mixin form-in-jumbotron-992() {
	section#jumbotron {
		#form-in-jumbotron-container {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;

			order: 1;
		}
	}
}

@mixin owl-carousel-in-jumbotron-992() {
	section#jumbotron {
		.owl-carousel {
			order: 0;

			.owl-item {
				display: flex;
				margin-top: auto;

				.owl-carousel-inner-item {
					padding-left: 5rem;
					padding-right: 5rem;
				}
			}
		}
	}
}