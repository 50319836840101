@mixin advantage-row() {
	section#advantage-row {
		border-bottom: 5px solid #ebebeb;

		h1 {
			font-size: 1.5rem;
			color: $primary;
			font-weight: 800;
			text-transform: uppercase;
			border-top: 1px solid $primary;
			border-bottom: 1px solid $primary;
			display: inline-block;
		}

		.row {
			> div {
				text-align: center;

				&:nth-child(2n) {
					background-color: #ebebeb;
				}

				ul {
					text-align: left;
					list-style-type: none;

					li {
						font-size: 0.8rem;
						margin: 0.5rem 0;
						display: flex;

						&:before {
							background-image: url('/wp-content/uploads/2022/03/chevron.png');
							background-size: unset;
							background-repeat: no-repeat;
							content: '';
							width: 1rem;
							height: 1rem;
							margin-right: 0.5rem;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}