@mixin wir-suchen-fuer-dich {
	#wir-suchen-fuer-dich {
		display: none;
	}
}

@mixin wir-suchen-fuer-dich-1200 {
	#wir-suchen-fuer-dich {
		display: block;
		position: fixed;
		right: 0;
		bottom: 30px;
	}
}

@mixin optin-click-loader {
	.lds-dual-ring {
		display: inline-block;
		width: 80px;
		height: 80px;

		&:after {
			content: " ";
			display: block;
			width: 64px;
			height: 64px;
			margin: 8px;
			border-radius: 50%;
			border: 6px solid orange;
			border-color: orange transparent orange transparent;
			animation: lds-dual-ring 1.2s linear infinite;
		}
	}

	@keyframes lds-dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

@mixin privacy-note {
	.privacy-note p,
	.privacy-note i {
		display: inline;
		vertical-align: middle;
	}

	.privacy-note i {
		color: orange;
		margin-right: .25rem;
		font-size: 1.25rem;
	}
}

@mixin dark-modal {
	.modal {
		.modal-content {
			background-color: rgba(0, 0, 0, 1);
			text-align: center;
			color: $primary;
			padding: 2rem;

			h1 {
				font-weight: 800;
			}

			h2 {
				font-size: 1.5rem;
				font-weight: 400;
			}
		}
	}
}

@mixin flex-modal {
	.modal-dialog {
		display: flex;
		height: 100%;

		.modal-content {
			align-self: center;
		}
	}
}

@mixin boxed-site {
	.transluent {
		background-image: url('../images/background-2.jpg');
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		height: 55rem;
	}

	#page {
		//max-width: 1560px;
		//margin: 0 auto;
		///background-color: white;
	}
}

@mixin lead-section {
	.lead-section h1 {
		font-size: 2rem;
		font-weight: 300;

		small {
			display: block;
			font-weight: 200;
		}
	}

	.lead-section {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;

		@media (min-width: 992px) {
			padding-left: $navbar-brand-offset-large;

			h1 {
				font-size: 2.5rem;
			}
		}
	}
}

@mixin gray-maps {
	#wrapper-maps {
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: grayscale(100%);
	}
}

@mixin offset-body() {
	body {
		padding-top: 144px;
	}
}

@mixin offset-body-992() {
	body {
		padding-top: 0;
	}
}

@mixin scrolled-body($navbar-elements-are-animated: true) {
	@if $navbar-elements-are-animated {
		.navbar, .navbar-brand, .navbar-brand img, .navbar-toggler, .navbar-nav .nav-item {
			transition: line-height .5s ease-in-out;
		}

		.navbar-brand {
			// transition: transform 0.3s ease-in-out;
			transition: margin 0.3s ease-in-out;
		}

		.navbar-brand img {
			transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
		}
	}

	body.is-scrolled {
		.navbar, .headline {
			background-color: #fff;
			color: black;

			a, .navbar-nav .nav-item .nav-link {
				color: black;

				&:before {
					background-color: #b09d65;
				}
			}
		}

		.navbar, .navbar-brand, .navbar-brand img, .navbar-toggler, .navbar-nav .nav-item {
			line-height: $navbar-height-large-when-scrolled;
		}

		.navbar-brand img {
			// height: $navbar-height-large-when-scrolled;
		}

		.navbar {
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
		}

		.navbar-brand {
			// transform: translateY(-2rem); // this will not affect height, but we do want that
			margin-top: -2rem;
		}

		.headline {
			background-color: #f5f5f5;
			border-bottom: transparent;
		}
	}
}

@mixin front-page-tabs {
	.front-page-tabs {
		border: none;
		white-space: nowrap;
		font-size: 0;
		overflow-x: scroll;
		overflow-y: hidden;
		box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);

		@include momentum-scrolling();

		.nav-item {
			margin: 0;
			padding: 0;
			float: none;
			display: inline-block;
			text-align: center;

			&+.nav-item {
				margin: 0;
			}
		}

		.nav-link {
			border: none;
			line-height: 3.625rem;
			font-size: $font-size-sm;
			font-weight: 400;
			font-family: $headings-font-family;
			text-transform: uppercase;
			color: $secondary;
			padding: 0 1.5rem;

			&:hover, &:focus, &:active, &.active {
				background-color: $primary;
				text-decoration: none;
				color: white;
			}

			 &.active {
			 	font-weight: 500;

			 	&:hover, &:focus, &:active {
					background-color: $primary;
					color: white;
			 	}
			 }
		}

		@media (min-width: 768px) {
			overflow: auto;
			overflow-x: auto;
			overflow-y: auto;
		}
	}
}