@mixin employer-row() {
	section#employer-row {
		text-align: center;

		.row {
			> div {
				&:nth-child(2n) {
					padding: 3rem;
					background-color: #ebebeb;
				}
			}
		}

		h1 {
			font-size: 2rem;
		}

		img {
			display: block;
			margin: 0 auto;
		}

		.btn {
			padding: 0.5rem 4rem;
		}
	}
}
