@mixin fix-textarea-height() {
	textarea.form-control {
		min-height: 300px;
	}
}

@mixin add-margin-before-h2-in-long-text() {
	h2 {
		margin-top: 1.5rem;
	}

	h2.accordion-header {
		margin-top: 0;
	}
}