@mixin page-template-default() {
	#content {
		margin-top: $navbar-height + $navbar-padding-y + $navbar-brand-overlap;
		margin-bottom: 3rem;
	}

	#page-wrapper {
		margin-top: $navbar-height + $navbar-padding-y + $navbar-brand-overlap;

		&.hasDecor {
			margin-top: 0;

			#content {
				margin-top: 0;
			}
		}
	}
}

@mixin page-template-default-992($show-seperator: false) {
	#content {
		margin-top: $navbar-height + $navbar-padding-y + $navbar-brand-overlap-large;
	}

	#page-wrapper {
		margin-top: $navbar-height + $navbar-padding-y + $navbar-brand-overlap-large;

		&.hasDecor {
			margin-top: 0;

			#content {
				margin-top: 0;
			}
		}
	}

	.page-template-default {
		@if $show-seperator {
			#primary {
				padding-right: $grid-gutter-width;
				border-right: 1px solid #d7d7d7;
				margin-right: -1px;
			}

			#secondary {
				padding-left: $grid-gutter-width;
				border-left: 1px solid #d7d7d7;
			}
		}
	}
}