@mixin front-page() {
	body.home {
		background-color: #eeeeee;
		// padding-top: 0;
	}

	section#more-about {
		text-align: center;
		padding: 5rem 0;

		h1 {
			font-size: 2.5rem;
			color: #454242;
			font-weight: 600;
		}

		img {
			vertical-align: middle;
			height: 7rem;
		}

		span {
			vertical-align: middle;
		}

		p {
			font-weight: 300;
		}

		button {
			font-size: 1.25rem;
		}

		h4 {
			color: $primary;
			font-weight: 600;

			span {
				display: block;
				font-weight: 300;
			}
		}
	}

	section#branches {
		i {
			font-size: 1.5rem;
			vertical-align: middle;
		}

		.first-branch, .second-branch {
			background-color: #f8f8f8;
			border-color: #f8f8f8;

			.btn {
				background-color: $tertiary;
				border-color: $tertiary;
			}

			&:hover, &:focus, &:active, &.active {
				background-color: #f0f1ec;
				border-color: #f0f1ec;

				.btn {
					background-color: $primary;
					border-color: $primary;
				}
			}
		}

		img {
			height: 1.25rem;
		}

		h1 {
			color: $primary;
			margin-bottom: 2rem;
			width: 100%;

			span {
				display: block;
				font-weight: 300;
				font-size: 1.75rem;
			}
		}

		button {
			border-radius: 0;
		}
	}

	section#advise {
		background-image: linear-gradient(0deg, rgba(29,86,54,0.9) 0%, rgba(29,86,54,0.9) 100%, rgba(29,86,54,0.9) 100%, rgba(29,86,54,0.9) 100%), url('/wp-content/uploads/2021/11/solar.jpg');
		background-size: cover;
		background-position: center center;
		// background-attachment: fixed;
		padding-top: 10rem;
		padding-bottom: 10rem;
		text-align: center;
		// display: flex;
		// align-items: center;
		// height: 75vh;

		.about {
			color: #fff;

			h1 {
				font-size: 2.5rem;
				color: inherit;
			}

			img {
				vertical-align: middle;
				height: 7rem;
			}

			p {
				font-weight: 300;
			}
		}
	}
}

@mixin front-page-576() {

	section#more-about {
		h1 {
			font-size: 3.5rem;
		}
	}

	section#advise {
		.about {
			h1 {
				font-size: 3.5rem;
			}
		}
	}
}

@mixin front-page-768() {

	section#more-about {
		h1 {
			font-size: 4.5rem;
		}
	}

	section#advise {
		.about {
			h1 {
				font-size: 4.5rem;
			}
		}
	}
}

@mixin front-page-992() {

	section#more-about {
		h1 {
			font-size: 5.5rem;
		}
	}

	section#advise {
		.about {
			h1 {
				font-size: 5.5rem;
			}
		}
	}
}