@mixin page-template-with-background-image() {
	.page-template-page-with-background-image {
		#page-wrapper {
			margin: 0;

			&:after {
				content: "";
				position: fixed;
				top: 0;
				height: 100vh;
				left: 0;
				right: 0;
				z-index: -1;
				background: url('/wp-content/uploads/2021/09/shutterstock_749854216.jpg') center center;
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
			}
		}
	}
}