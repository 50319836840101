@mixin sidebyside() {
	section#sidebyside {
		text-align: left;
		background-color: #fff;

		.row {
			> div:first-child, > div:last-child {
				background-repeat: no-repeat;
				background-position: 95% bottom;
				background-size: auto 15rem;
			}

			> div:first-child {
				background-image: url('/wp-content/uploads/2022/03/mann-1.png');
			}

			> div:last-child {
				background-image: url('/wp-content/uploads/2022/03/mann-2.png');
			}

			> div {
				&:nth-child(2n+1) {
					position: relative;
				}
			}
		}

		h1 {
			font-size: 1.5rem;
		}

		ul {
			padding: 0;

			li {
				list-style-type: none;
				font-weight: 600;

				a {
					color: #000;
					text-decoration: none;

					&:hover, &:focus, &:active {
						color: $primary;
					}
				}
			}
		}

		img {
			position: absolute;
			margin: auto;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}

		.btn {
			padding: 0.25rem 1.75rem;
		}

		.separate {
			background-color: #ebebeb;
			background-image: url('/wp-content/uploads/2022/03/zielscheibe.png');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 189px 209px;
			min-height: 350px;
			text-align: center;
			display: grid;
			place-items: center;
			position: relative;

			.separate-content {
				z-index: 1;

				.btn {
					padding: 0.5rem 1rem;
					border-radius: 0;
					font-size: 1.5rem;
					text-transform: uppercase;
					font-weight: 800;
				}

			}
		}
	}
}

@mixin sidebyside-992() {
	section#sidebyside {
		.row {
			> div:first-child, > div:last-child {
				background-image: none;
			}
		}

		.separate {
			.separate-content {
				.btn {
					font-size: 2rem;
				}
			}
		}
	}
}

@mixin sidebyside-1200() {
	section#sidebyside {
		.row {
			> div:first-child, > div:last-child {
				background-position: 95% bottom;
			}

			> div:first-child {
				background-image: url('/wp-content/uploads/2022/03/mann-1.png');
			}

			> div:last-child {
				background-image: url('/wp-content/uploads/2022/03/mann-2.png');
			}

		}
	}
}

@mixin sidebyside-1700() {
	section#sidebyside {
		.row {
			> div:first-child, > div:last-child {
				background-position: 70% bottom;
			}
		}
	}
}