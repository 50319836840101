@mixin whatsapp() {
	#whatsapp {
		position: fixed;
		bottom: 1rem;
		right: 1rem;
		z-index: 1;
		font-size: 2.5em;
	}

	@media screen and (min-width: 992px) {
		#whatsapp {
			bottom: 2rem;
			right: 2rem;
			font-size: 3em;
		}
	}

	@-webkit-keyframes pulse {
		0% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		50% {
			-webkit-transform: scale(2);
			transform: scale(2);
		}
		100% {
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}

	#whatsapp a {
		display: block;
		-webkit-animation: pulse 2s infinite;
		animation: pulse 2s infinite;
	}

	#whatsapp .fa-circle {
		color: white;
	}

	#whatsapp .fa-whatsapp {
		color: #25D366;
	}
}