.navbar-mobile {
	display: flex;
	flex-direction: row;
	width: 100%;

	color: #3e322c;

	div {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;

		&:first-child > * {
			margin-left: auto;
		}

		&:last-child > * {
			margin-right: auto;
		}
	}
}

@mixin navbar-brand-in-the-middle-992() {
	.collapse.navbar-collapse {
		div {
			flex: 1;
			display: flex;
			justify-content: center;
		}

		.navbar-brand {
			flex-grow: 0;
			padding-left: 20px;
			padding-right: 20px;
		}

		#right-box {
			ul {
				margin-right: auto;
			}
		}

		#left-box {
			ul {
				margin-left: auto;
			}
		}
	}
}

@mixin nav-link-subtitles() {
	.navbar-nav.nav-link-subtitles {
		.nav-link-inner {
			line-height: 1;
			display: inline-block;
			vertical-align: middle;
			font-size: 18pt;

			span {
				color: #1d5636;
				font-weight: normal;
			}

			small {
				font-size: 0.65em;
				font-weight: light;
				display: block;
			}
		}
	}
}

@mixin collapsing-navbar() {
	@media (max-width: 767px) {

		#navbarNavDropdown {
			padding: 0;
			padding-top: 6rem;

			.navbar-nav .nav-item {
				display: block;
			}

			float: none;
			max-height: 340px;
			overflow-x: visible;
			overflow-y: scroll;
			padding-left: $grid-gutter-width/2;
			padding-right: $grid-gutter-width/2;
			-webkit-overflow-scrolling: touch;

			&.in {
				overflow-y: auto;
			}

			.dropdown-menu {
				position: static;
				top: auto;
				left: auto;
				float: none;
				min-width: 0;
				padding: 0;
				margin: 1rem 0 1rem 0;
				text-align: center;
				border: none;
			}
		}

	}
}

// refactored on 11/08/2021
@mixin nav-link-underlined($underline-location: 'inside', $underline-height: 4px) {
	.navbar-nav.nav-link-underlined {
		@if $underline-location == 'inside' { // if the nav link underline is visually in the navlink
			overflow: hidden;
		}

		.nav-item {
			white-space: nowrap;
		}
	}
}

@mixin nav-link-underlined-992($underline-location: 'inside', $underline-height: 2px) {
	.navbar-nav.nav-link-underlined .nav-item {
		&.dropdown.open {
			.nav-link:before {
				display: none;
			}
		}

		.nav-link {
			// transition: 0.3s transform ease-in-out;

			&:before {
				content: " ";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: $underline-height;

				@if $underline-location == 'inside' { // if the nav link underline is visually in the navlink
					transform: translateY($underline-height);
				} @else { // if the nav link underline is visually under the navlink
					z-index: -1;
				}

				background-color: white; // will be #b09d65 when sticky
				@include transition(transform .25s ease-in-out);
			}

			// if active-class is added on nav-link, the code is needed here
			// &:hover, &:focus, &:active, &.active, &.open {
			// 	&:before {
			// 		transform: translateY(0);
			// 	}
			// }
		}

		// if active-class is added on nav-item, the code is needed here
		&:hover, &:focus, &:active, &.active, &.open {
			.nav-link::before {
				@if $underline-location == 'inside' { // if the nav link underline is visually in the navlink
					transform: translateY(0);
				} @else { // if the nav link underline is visually under the navlink
					transform: translateY($underline-height); // if the nav link underline is visually under/outside the navlink
					z-index: 0; // if the nav link underline is visually under/outside the navlink
				}
			}
		}
	}
}

@mixin nav-link-decorated-with-backgroundimage() {
	.navbar-nav .nav-item {
		&:hover, &:focus, &:active, &.active, &.open {
			.nav-link {
				background-image: url('../images/menu-decorator.svg');
				background-repeat: no-repeat;
				background-position: top center;
				background-size: 38px 15px;
			}
		}
	}
}

@mixin nav-link-decorated-with-fontawesome() {
	.navbar-nav .nav-item {
		.nav-link {
			transition: 0.3s all ease-in-out;

			&:after {
				content: "\f0c4";
				font-family: "FontAwesome";
				color: $primary;
				font-size: 1rem;
				position: absolute;
				animation: slideIn 1s forwards;
				visibility: hidden;
				left: 0;
				margin-right: 1rem;
				@include transition(all 0.5s ease-in-out);
			}
		}

		&:hover, &:focus, &:active, &.active, &.open {
			.nav-link:after {
				visibility: visible;
			}
		}
	}
}

@mixin nav-link-outlined() {
	.navbar-nav .nav-item {
		.nav-link {
			transition: 0.3s border ease-in-out;
			line-height: $navbar-height;
			display: inline-block;
			vertical-align: middle;
			padding: 0 0.5rem 0 0.5rem;
		}
	}
}

@mixin dropdown-prefixes() {

	.dropdown-prefix {
		height: 10px;
		width: 10px;
		background: url('images/raute-sprite.svg') no-repeat top center;
		background-size: 10px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 0.25rem;
	}

}

@mixin dropdown-menus() {
	.dropdown-menu {
		margin: 0;
		//border-top: 4px solid $primary;
		//border-bottom: 4px solid $primary;
		//border-top-left-radius: 0;
		//border-top-right-radius: 0;
		background-color: rgba(255, 255, 255, 1);
		//background-color: rgb(19, 19, 19);
		//border-color: $primary;
		padding: 0;
		font-size: $font-size-sm;
		font-weight: 300;
		//margin-top: -4px;

		//border-radius: 0;
		//border-style: solid;
		//border-width: 4px 2px 4px 2px;
		//border-color: $primary;
		border: none;

		box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, .15);

		border-bottom-left-radius: .25rem;
		border-bottom-right-radius: .25rem;
	}

	.dropdown-item {
		line-height: 3rem;
		//text-transform: uppercase;
		border-top: 1px solid #d7d7d7;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $body-color;
		padding: 0;
		width: auto;
		margin: 0 1.5rem 0 1.5rem;

		&:first-child {
			border-top: 0;
		}

		&:hover, &:focus, &:active {
			background-color: transparent;
			color: $primary;

			.dropdown-prefix {
				background-position: bottom center;
			}
		}

		&.active, &.active:focus, &.active:hover, &.active:active {
			background-color: transparent;
			color: $primary;

			.dropdown-prefix {
				background-position: bottom center;
			}
		}
	}
}