@mixin cardbox-row() {
	section#cardbox-row {
		background-color: $primary;
		padding-top: 5rem;
		// padding-bottom: 5rem; // not on mobile

		h1 {
			color: #fff;
			border-color: #fff;
		}

		.row {
			text-align: center;

			.card {
				border: none;
			}

			.card-body {
				display: grid;
				place-items: center;
				align-content: center;
			}

			> div {
				height: 20rem;
				padding: 1rem 2rem;
				display: grid;
				align-content: center;
				color: white;

				h2 {
					font-weight: 200;
					font-size: 2rem;
					text-transform: uppercase;
					color: inherit;
				}

				background-color: $tertiary;

				&:nth-child(2n+0) {
					background-color: $secondary;

					h2 {
						color: $primary;
					}
				}

				&:last-child {
					background-color: #ebebeb; // gray on mobile

					h2 {
						font-weight: 600;
					}
				}
			}

			.btn {
				&:hover, &:focus, &:active {
					background-color: darken($primary,15%);
					color: #fff;
				}
			}
		}
	}
}

@mixin cardbox-row-576() {
	section#cardbox-row {
		padding-bottom: 5rem;
	}
}

@mixin cardbox-row-992() {
	section#cardbox-row {
		position: relative;

		.row {
			margin-top: 17rem;
			position: relative;

			&:before {
				content: "";
				background-image: url(/wp-content/uploads/2022/03/gruende-sprechblase.png);
				position: absolute;
				left: 25%;
				top: -399px;
				width: 691px;
				height: 399px;
			}

			> div:last-child {
				background-color: white;
			}
		}
	}

	.page-template-employee {
		section#cardbox-row {
			.row {
				&:before {
					background-image: url(/wp-content/uploads/2022/05/gruende-arbeitnehmer.png);
					width: 722px;
				}
			}
		}
	}
}
