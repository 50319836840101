@mixin testimonials() {
	section#testimonials {
		background-color: #fff;
		background-position: bottom;
		background-size: cover;

		.testimonials {
			text-align: center;
			padding: 5rem 0;

			.btn {
				font-size: 1.5rem;
				
				img {
					position: unset;
					display: inline-block;
				}
			}

			img {
				display: none;
			}

			.btn {
				padding: 0.25rem 1rem;
			}
		}

		#owl-testimonials {
			.testimonial-slide {
				margin: 0 auto;
				max-width: 870px;

				.fa {
					color: $secondary;
					font-size: 3rem;
				}

				.quote-icon {
					background-color: #5b7d5b;
					color: white;
					font-size: 2rem;
					padding: 2rem 5rem;
					margin-bottom: 2rem;
					display: inline-block;
				}

				.slide-name {
					font-size: 1rem;
				}

				.testimonial-placeholder, img {
					height: 67px;
					width: 103px;
					background-color: #d0d0d0;
					margin: 0 auto 1.5rem auto;
				}

				.testimonial-details {
					font-size: .875rem;

					span {
						font-size: 0.9375rem;
						display: block;
						color: $secondary;
					}
				}

				p {
					font-size: 1rem;
					font-weight: 300;
				}

				span {
					font-family: $headings-font-family;
					font-weight: 400;
					font-size: 1.3125rem;
					color: $secondary;

					small {
						text-transform: uppercase;
						color: #9d9d9d;
						font-size: 0.75rem;
					}
				}
			}

			&.owl-theme .owl-dots {
				display: block;
			}

			&.owl-theme .owl-dots .owl-dot {
				background-color: transparent;
				border: none;
				text-align: center;
				vertical-align: middle;
				outline: none;
				padding: 3px !important;

				span {
					display: inline-block;
					background: transparent;
					vertical-align: middle;
					border: 2px solid $primary;
					margin: 0;
				}

				&.active, &:hover, &:focus, &:active {
					span {
						border-color: $primary;
						background: $primary;
					}
				}
			}
		}
	}
}

@mixin testimonials-576() {
	section#testimonials {
		position: relative;

		.testimonials {
			img {
				display: inline-block;
				position: absolute;
				left: 15rem;
				right: 0;
				bottom: 0;
				margin: 0 auto;
			}
		}
	}
}

@mixin testimonials-1200() {
	section#testimonials {

		.testimonials {
			img {
				left: 40rem;
			}
		}
	}
}