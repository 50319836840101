@mixin toggler-default() {
	.navbar-toggler {
		border: none;
		height: $navbar-height;
		position: absolute;
		display: block; // keeps it left, when the navbar has text-align center
		right: 2rem;
		color: $primary;
		font-family: 'Arial';
		font-size: 1.75rem;

		transform: scaleX(1.5);

		img {
			width: 5rem;
			padding-right: 1rem;
		}

		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}

@mixin toggler-crossed() {
	@-webkit-keyframes navbar-toggler-top-in {

	    10% {
	        top: 8px;
	    }

	    30% {
	        top: 0;
	        -webkit-transform: rotate(0);
	        transform: rotate(0);
	        background-color: $primary;
	    }

	    100% {
	        top: 0;
	        -webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	        background-color: $primary;
	    }

	}

	@keyframes navbar-toggler-top-in {

	    10% {
	        top: 8px;
	    }

	    30% {
	        top: 0;
	        -webkit-transform: rotate(0);
	        transform: rotate(0);
	        background-color: $primary;
	    }

	    100% {
	        top: 0;
	        -webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	        background-color: $primary;
	    }

	}

	@-webkit-keyframes navbar-toggler-bottom-in {

	    10% {
	        bottom: 8px;
	    }

	    30% {
	        bottom: 0;
	        -webkit-transform: rotate(0);
	        transform: rotate(0);
	        background-color: $primary;
	    }

	    100% {
	        bottom: 0;
	        -webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	        background-color: $primary;
	    }

	}

	@keyframes navbar-toggler-bottom-in {

	    10% {
	        bottom: 8px;
	    }

	    30% {
	        bottom: 0;
	        -webkit-transform: rotate(0);
	        transform: rotate(0);
	        background-color: $primary;
	    }

	    100% {
	        bottom: 0;
	        -webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	        background-color: $primary;
	    }

	}

	@-webkit-keyframes navbar-toggler-in {

	    25% {
	        -webkit-transform: rotate(0);
	        transform: rotate(0);
	    }

	    100% {
	        -webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	    }

	}

	@keyframes navbar-toggler-in {

	    25% {
	        -webkit-transform: rotate(0);
	        transform: rotate(0);
	    }

	    100% {
	        -webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	    }

	}

	@-webkit-keyframes navbar-toggler-top-out {

	    0% {
	        -webkit-transform: rotate(-45deg) translateY(0);
	        transform: rotate(-45deg);
	    }

	    50% {
	        -webkit-transform: rotate(0) translateY(0);
	        transform: rotate(0) translateY(0);
	    }

	    100% {
	        -webkit-transform: translateY(-8px);
	        transform: translateY(-8px);
	    }

	}

	@keyframes navbar-toggler-top-out {

	    0% {
	        -webkit-transform: rotate(-45deg) translateY(0);
	        transform: rotate(-45deg) translateY(0);
	    }

	    50% {
	        -webkit-transform: rotate(0) translateY(0);
	        transform: rotate(0) translateY(0);
	    }

	    100% {
	        -webkit-transform: translateY(-8px);
	        transform: translateY(-8px);
	    }

	}

	@-webkit-keyframes navbar-toggler-bottom-out {

	    0% {
	        -webkit-transform: rotate(45deg) translateY(0);
	        transform: rotate(45deg) translateY(0);
	    }

	    50% {
	        -webkit-transform: rotate(0) translateY(0);
	        transform: rotate(0) translateY(0);
	    }

	    100% {
	        -webkit-transform: translateY(8px);
	        transform: translateY(8px);
	    }

	}

	@keyframes navbar-toggler-bottom-out {

	    0% {
	        -webkit-transform: rotate(45deg) translateY(0);
	        transform: rotate(45deg) translateY(0);
	    }

	    50% {
	        -webkit-transform: rotate(0) translateY(0);
	        transform: rotate(0) translateY(0);
	    }

	    100% {
	        -webkit-transform: translateY(8px);
	        transform: translateY(8px);
	    }

	}

	.navbar-toggler {
		// border: none;
		height: $navbar-height;
		// position: absolute;
		// display: block; // keeps it left, when the navbar has text-align center
		// right: 2rem;

		&:focus {
			outline: none;
			box-shadow: none;
		}

		.icon-bar, .icon-bar:before, .icon-bar:after {
			height: 3.25px;
			background-color: #fff;
			display: block;

			content: "";
			position: absolute;
		}

		.icon-bar {
			position: relative;
			width: 32px;

			&:before {
				left: 0;
				right: 0;

				-webkit-transform: translateY(8px);
				transform: translateY(8px);
			}

			&:after {
				left: 0;
				right: 0;

				-webkit-transform: translateY(-8px);
				transform: translateY(-8px);
			}

		}
	}

	html.interacted {

		.navbar-toggler {
			.icon-bar, .icon-bar:before, .icon-bar:after {
	            -webkit-backface-visibility: hidden;
				@include transition(background-color 0s cubic-bezier(.8, 0, .1, 1) 200ms);
				@include transform-origin(center);
				-webkit-animation-name: navbar-toggler-out;
				-webkit-animation-duration: 750ms;
				-webkit-animation-fill-mode: forwards;
				animation-name: navbar-toggler-out;
				animation-duration: 750ms;
				animation-fill-mode: forwards;
			}

			.icon-bar {
		        -webkit-animation-name: navbar-toggler-out;
		    	animation-name: navbar-toggler-out;
			}

			.icon-bar:after {
				-webkit-transform: translateY(0px);
				transform: translateY(0px);
		   	    -webkit-animation-name: navbar-toggler-top-out;
		   	 	animation-name: navbar-toggler-top-out;
			}

			.icon-bar:before {
				-webkit-transform: translateY(0px);
				transform: translateY(0px);
		        -webkit-animation-name: navbar-toggler-bottom-out;
		    	animation-name: navbar-toggler-bottom-out;
			}
		}
	}

	body.slidebar-open {
		.navbar-toggler {

			.icon-bar, .icon-bar:before, .icon-bar:after {
				background-color: $primary;

				@include transition(background-color 0s cubic-bezier(.8, 0, .1, 1) 200ms);
				@include transform-origin(center);

				-webkit-animation-duration: 750ms;
				-webkit-animation-fill-mode: forwards;
				animation-duration: 750ms;
				animation-fill-mode: forwards;
			}

			.icon-bar {
				background-color: transparent;
	            -webkit-animation-name: navbar-toggler-in;
	    		animation-name: navbar-toggler-in;
			}

			.icon-bar:after {
	   	        -webkit-animation-name: navbar-toggler-top-in;
	   	 		animation-name: navbar-toggler-top-in;
			}

			.icon-bar:before {
	            -webkit-animation-name: navbar-toggler-bottom-in;
	    		animation-name: navbar-toggler-bottom-in;
			}
		}
	}

}