@mixin custom-buttons() {
	.btn {
		padding: 16px 20px;
		font-weight: 300;

		i, span {
			vertical-align: middle;
		}

		i {
			font-weight: 600;
			font-size: 1.5em;
		}
	}

	.btn-primary {
		color: white;
	}

	.btn-blue {
		@include button-variant(
			$background: $primary,
			$border: $primary,
			$color: #fff,
			$hover-background: #a1d2eb,
			$hover-color: $primary,
			$hover-border: #a1d2eb
		);
		font-weight: 600;
	}

	.btn-lightblue {
		@include button-variant(
			$background: #b7e0f1,
			$border: #b7e0f1,
			$color: $primary,
			$hover-background: #a1d2eb,
			$hover-color: $primary,
			$hover-border: #a1d2eb
		);
		font-weight: 600;
	}

	.btn-whatsapp {
		@include button-variant(
			$background: #25D366,
			$border: #25D366,
			$color: #fff,
			$hover-background: #25D366,
			$hover-color: #fff,
			$hover-border: #25D366
		);
		font-weight: 600;
	}

	.btn-select {
		&:not(.collapsed) {
		  color: #a1d2eb;
		  @include gradient-bg($primary);
		  border-color: $primary;
		  @if $enable-shadows {
			// @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
		  } @else {
			// Avoid using mixin so we can pass custom focus shadow properly
			// box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
		  }
		}

		// box-shadow: none;
		@include button-variant(
			$background: #b7e0f1,
			$border: #b7e0f1,
			$color: $primary,
			$hover-background: #fff,
			$hover-color: $primary,
			$hover-border: #a1d2eb
		);
		font-weight: 800;
		padding: 0.5rem 1rem;

	}

}