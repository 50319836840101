@import "mixins/3rdparty";
@import "mixins/social-media";
@import "mixins/transition";
@import "mixins/transform";
@import "mixins/utilities";
@import "mixins/flip-cards";
@import "mixins/navbar";
@import "mixins/footers";
@import "mixins/navbar-toggler";
@import "mixins/owlcarousel";
@import "mixins/scaffolding";
@import "mixins/has-decor";
@import "mixins/related";
@import "mixins/fixes";
@import "mixins/gallery";
@import "mixins/video";
@import "mixins/whatsapp";

// @import "helpers";
// @import "keyframes";
// @import "transitions";
@import "jumbotron";
@import "sidebyside";
@import "services";
@import "social";
//@import "row-info";
@import "cardbox-row";
@import "booking";
@import "testimonials";
@import "employer-row";
@import "advantage-row";
@import "faq";
@import "ceos";
@import "page";
// @import "wf-underpage";
@import "google-fonts";
@import "wordpress";
@import "fixed-top";
// @import "split-screen";
@import "front-page";
@import "page-template-default";
@import "page-template-with-background-image";
@import "page-catalog";
@import "page-object";

@import "headline";
@import "navbar";
@import "buttons";
// @import "slide_menu";
// @import "loaders";
// @import "owlcarousel"; alt und button problem

@include fix-textarea-height();
@include add-margin-before-h2-in-long-text();
@include wp-adminbar();
@include skip-link();
@include wp-widgets();
@include has-fixed-top($navbar-is-translucent: false);
@include custom-buttons();
@include collapsing-navbar();
@include headline();
@include navbar($navbar-brand-is-absolute: true);
@include toggler-crossed();
// @include social-media-colors();
// @include video();
@include nav-link-underlined();
// @include split-screen();
@include flip-cards();
@include front-page();
@include jumbotron();
@include owl-carousel-in-jumbotron();
@include form-in-jumbotron();
@include page-template-default();
@include page-template-with-background-image();
// @include page-catalog();
// @include about-site(); oekobau only
// @include company-site(); oekobau only
// @include renewable-energy(); oekobau only
// @include picture-bar();
@include nav-link-subtitles();
//@include scrolled-body();
// @include booking();
// @include booking-widget();
// @include wf-underpage();
@include testimonials();
@include faq();
@include ceos();
@include services();
@include social();
@include cardbox-row();
@include sidebyside();
@include employer-row();
@include advantage-row();
@include page();
// @include grid-gallery($use-fancybox: true);
@include zrjb(); // todo: test if still needed

@include full-footer();
@include footer-colophon();
@include wir-suchen-fuer-dich();
@include whatsapp();

@media (min-width: 576px) {
	@include has-fixed-top-576($navbar-is-translucent: false);
	@include headline-576();
	@include navbar-576($navbar-brand-is-absolute: true);
	@include front-page-576();
	@include jumbotron-576();
	@include testimonials-576();
	@include social-576();
	@include cardbox-row-576();
}

@media (min-width: 768px) {
	@include headline-768();
	// @include page-catalog-768();
	@include front-page-768();
	@include jumbotron-768();
	// @include picture-bar-768();
	// @include wf-underpage-768();
	@include services-768();
	@include social-768();
}

@media (min-width: 992px) {
	// @include has-fixed-top-992($navbar-is-translucent: false);
	@include navbar-992($navbar-brand-is-absolute: true);
	// @include navbar-brand-in-the-middle-992();
	@include nav-link-underlined-992();
	// @include navbar-elements-992();
	// @include nav-link-decorated-with-fontawesome();
	// @include nav-link-decorated-with-backgroundimage();
	// @include split-screen-992();
	// @include page-catalog-992();
	@include front-page-992();
	@include jumbotron-992();
	@include owl-carousel-in-jumbotron-992();
	@include form-in-jumbotron-992();
	@include sidebyside-992();
	@include social-992();
	@include services-992();
	@include cardbox-row-992();
	@include page-template-default-992();
	@include ceos-992();
}

@media (min-width: 1200px) {
	// @include page-catalog-1200();
	// @include picture-bar-1200();
	@include jumbotron-1200();
	@include services-1200();
	@include testimonials-1200();
	@include social-1200();
	@include wir-suchen-fuer-dich-1200();
	@include sidebyside-1200();
}


@media (min-width: 1400px) {
	@include jumbotron-1400();
}

@media (min-width: 1500px) {
	@include jumbotron-1500();
}

@media (min-width: 1700px) {
	@include jumbotron-1700();
	@include sidebyside-1700();
}

@include nunito-sans();
// @include playfair-display();
// @include roboto-condensed();