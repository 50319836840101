@mixin google-reviews-badge {
	#google-reviews-badge {
		position: fixed;
		right: 20px;
		bottom: 30px;
		width: 200px;
		padding: 10px;
		border-top: 6px solid #4fce6a;
		border-radius: 2px 2px 0 0;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
		background-color: white;
		z-index: 1;
	}
}

@mixin zrjb() {
	// bewerbungsformular
	#zrjb-application-form-input-container {
		max-width: 500px;

		> div {
			@extend .mb-3;

			> label {
				@extend .form-label;
			}

			> select, > input {
				@extend .form-control;
			}
		}

	}

	#zrjb-attachment-wrapper, .select2-container {
		@extend .mb-3;
	}

	.zrjb-send-form-button {
		@extend .btn;
		@extend .btn-primary;
	}

	#zrjb-datenschutz-infotext {
		@extend .mb-3;

		> label {
			@extend .form-check;

			> input {
				@extend .form-check-input;
			}
		}
	}

	.zrjb-file-upload-button {
		@extend .btn;
		@extend .btn-secondary;
	}

	// jobliste

	.zrjb-filter-keywords {
		@extend .mb-3;

		> label {
			@extend .form-label;
		}

		> input {
			@extend .form-control;
		}
	}

	#zrjb-item-search-form {
		&:after {
			content: "Gefundene Stellen";
			@extend .h2;
		}
	}

	#zrjb-itemlist-container {
		@extend .row;

		> div {
			@extend .col-md-3;
			@extend .my-3;
			border-right: 1px solid #f5f5f5;
			border-bottom: 1px solid #f5f5f5;
			padding-bottom: 15px;
			padding-top: 15px;

			> div {
				h4 {
					margin: 0;
					word-break: break-all;
				}
			}
		}
	}
}