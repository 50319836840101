@mixin ceos() {
	section#ceos {
		.mk {
			background-color: $tertiary;
			color: #fff;

			h1 {
				color: #fff;
				border-color: #fff;
			}
		}

		.rk {
			h1 {
				color: $tertiary;
				border-color: $tertiary;
			}
		}

		a {
			font-size: 1.5rem;
			padding: 0 2rem;
		}
	}
}

@mixin ceos-992() {
	section#ceos {
		.row {
			.img-col {
				position: relative;

				img {
					position: absolute;
					width: 30rem;
					bottom: 0;
				}

				a {
					position: absolute;
					z-index: 1;
				}
			}
		}

		.mk {
			img {
				right: calc(var(--bs-gutter-x) * .5);
			}

			a {
				bottom: 7rem;
				right: 5rem,
			}
		}

		.rk {
			img {
				left: calc(var(--bs-gutter-x) * .5);
			}

			a {
				bottom: 7rem;
				left: 5rem;
			}
		}
	}
}