@mixin headline() {
	.headline, .sub-headline {
		display: none;
	}
}

@mixin headline-576() {
	.headline, .sub-headline {
		display: block;
		line-height: $headline-height;
		// border-bottom: 1px solid rgba(238, 238, 238, 0.5);
		background-color: $primary;
		color: #fff;

		.dropdown-toggle {
			border-radius: 0.5rem;
			border: 1px solid #fff;
		}

		@include transition(all 0.2s ease-in-out);

		//font-family: 'Roboto Condensed', sans-serif;

		.container, .container-fluid {
			display: flex;
			gap: 1.5rem;
		}

		.address-container {
			margin-left: -12px;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			display: inline-block;
		}

		img {
			width: 1.5rem;
		}

		.btn {
			font-size: .875rem;
			padding: 0 0.5rem;
		}

		a, i {
			@include transition(color 0.2s linear);
		}

		> a, > i, > span {
			display: inline-flex;
		}

		span {
			display: inline-block;
			font-weight: 400;
			vertical-align: top;
			font-size: 0.8rem;
		}

		i {
			font-size: 1.5rem;
			vertical-align: middle;
			margin-right: .25rem;
		}

		a {
			color: #fff;
			text-decoration: none;

			&:hover, &:focus, &:active {
				color: #fff;

				i {
					color: inherit;
				}
			}
		}

		span.bull::before {
			display: inline-block;
			content: "\2022";
			margin-left: .5rem;
			margin-right: .5rem;
			font-weight: 900;
		}

		.dropdown {
			ul {
				li {
					a {
						background-color: #fff;
						color: $primary;
					}
				}
			}
		}
	}

	.sub-headline {
		background-color: $secondary;
	}
}

@mixin headline-768() {
	.headline, .sub-headline {
		span {
			font-size: 1rem;
		}
	}
}