@mixin full-footer() {
	#wrapper-footer {
		background-color: $tertiary;
		color: #fff;

		padding-top: 2.5rem;
		padding-bottom: 2.5rem;

		i {
			font-size: 1.5rem;
		}

		a {
			color: white;
			text-decoration: none;

			&:hover {
				color: shift-color(white, $link-shade-percentage);
			}
		}

		.footer-menu {
			h4 {
				font-size: 1rem;
				font-family: $font-family-sans-serif;
			}

			.nav-item {
				.nav-link {
					color: $secondary;
					// font-size: $font-size-sm;
					padding: 0;
					margin-right: 1rem;

					&:hover, &:focus, &:active {
						color: white;
					}

				}
			}
		}

		.footer-logo {
			img {
				display: block;
				padding-bottom: 1rem;
			}

			span {
				color: black;
				text-transform: uppercase;
				font-weight: 600;
				font-size: $font-size-lg;
				display: block;
			}
		}
	}
}

@mixin footer-colophon() {
	#colophon {
		line-height: 2.5rem;
		background-color: $secondary;
		color: $tertiary;

		.site-date {
			font-weight: 600;

			&:after {
				content: '|';
				margin-left: 1rem;
			}
		}

		a {
			color: $tertiary;
			text-decoration: none;
			font-weight: 600;

			&:first-child {
				&:after {
					content: '|';
					margin-left: 1rem;
				}
			}
		}

		img {
			display: block;
			margin: 0 auto;
		}
	}
}