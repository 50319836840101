@mixin social() {
	i.phone-icon, i.mail-icon, i.facebook-icon, i.instagram-icon {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		height: 28px;
		width: 28px;
		display: inline-block;
	}

	i.phone-icon {
		background-image: url('/wp-content/uploads/2022/05/phone-icon.svg');
	}

	i.mail-icon {
		background-image: url('/wp-content/uploads/2022/05/mail-icon.svg');
	}

	i.facebook-icon {
		background-image: url('/wp-content/uploads/2022/05/facebook-icon.svg');

		&.blue {
			background-image: url('/wp-content/uploads/2022/05/facebook-icon-blue.svg');
		}
	}

	i.instagram-icon {
		background-image: url('/wp-content/uploads/2022/05/instagram-icon.svg');

		&.blue {
			background-image: url('/wp-content/uploads/2022/05/instagram-icon-blue.svg');
		}
	}

	section#social {
		background-color: $primary;
		text-align: center;

		h1 {
			color: #fff;
			border-color: #fff;
		}

		.card {
			border: none;
			text-align: center;
			height: 100%;

			i {
				font-size: 2rem;
				color: $primary;
			}

			h5 {
				color: $primary;
				font-weight: 600;
			}

			.date {
				display: block;
				background-color: $tertiary;
				color: #fff;
				padding: 1rem;
			}
		}

		.btn {
			padding: 0.25rem 1.75rem;
		}
	}
}

@mixin social-576() {
	section#social {
		.card {
			text-align: left;
		}
	}
}

@mixin social-768() {
	section#social {
		.row {
			position: relative;

			> div:first-child {
				&:before {
					background-image: url('/wp-content/uploads/2022/03/social-worker.png');
					position: absolute;
					top: -8rem;
					left: 11rem;
					width: 166px;
					height: 144px;
					background-size: cover;
					content: '';
					z-index: 1;
				}
			}
		}

		h1 {
			padding: 0;
			background: none;
		}

		small {
			font-size: 14pt;
			line-height: 1;
		}
	}
}

@mixin social-992() {
	section#social {
		.row {
			&:before {
				top: -8rem;
				left: 3rem;
				right: 0;
			}
		}

		h1 {
			font-size: 2.5rem;
		}
	}
}

@mixin social-1200() {
	section#social {
		.row {
			&:before {
				left: 5rem;
			}
		}
	}
}
